<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div
      v-if="
        databaseBackups.databaseBackupsData != undefined &&
        databaseBackups.databaseBackupsData.length != 0
      "
    >
      <DatabaseBackupsTable
        :databaseBackupsData="databaseBackups.databaseBackupsData"
        :databaseBackupData="databaseBackups.databaseBackup"
        v-on:setDatabaseBackupData="setDatabaseBackupData($event)"
        v-on:downloadDatabaseBackup="downloadDatabaseBackup($event)"
        :pagination="databaseBackups.pagination"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="databaseBackups.pagination"
        v-on:changePage="changePage"
      />
      <DatabaseBackupDelete
        :databaseBackupData="databaseBackups.databaseBackup"
        v-on:refresh="getAllDatabaseBackups(1)"
      />
    </div>

    <DatabaseBackupFloatBtns
      :theFilterData="databaseBackups.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import DatabaseBackupsTable from "@/components/databaseBackups/DatabaseBackupsTable.vue";
import DatabaseBackupDelete from "@/components/databaseBackups/DatabaseBackupDelete.vue";
import DatabaseBackupFloatBtns from "@/components/databaseBackups/DatabaseBackupFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import DatabaseBackups from "@/models/databaseBackups/DatabaseBackups";
import { downloadFileFromServer } from "@/utils/functions";

export default {
  name: "DatabaseBackups",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    DatabaseBackupsTable,
    DatabaseBackupDelete,
    DatabaseBackupFloatBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      databaseBackups: new DatabaseBackups(),
    };
  },
  methods: {
    changePage(page) {
      this.databaseBackups.pagination.selfPage = page;
      this.getAllDatabaseBackups();
    },
    setDatabaseBackupData(data) {
      this.databaseBackups.databaseBackup.fillData(data);
    },
    downloadDatabaseBackup(databaseBackup) {
      try {
        downloadFileFromServer(
          this.language,
          this.userAuthorizeToken,
          databaseBackup.databaseBackupPath
        );
      } catch (error) {
        this.exceptionMsg = this.$t("errorCatch");
      }
    },
    search(data) {
      this.databaseBackups.filterData.fillData(data);
      this.databaseBackups.databaseBackupsData = [];
      this.getAllDatabaseBackups();
    },
    async getAllDatabaseBackups() {
      this.isLoading = true;
      try {
        const response =
          await this.databaseBackups.databaseBackup.getAllDatabaseBackups(
            this.language,
            this.userAuthorizeToken,
            this.databaseBackups.pagination,
            this.databaseBackups.filterData
          );

        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.databaseBackups.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.databaseBackups.databaseBackupsData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.databaseBackups.databaseBackupsData = [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.databaseBackups.databaseBackupsData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.databaseBackups.databaseBackupsData = [];
        this.exceptionImg = "illustrator-somethingWrong.svg";
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getAllDatabaseBackups();
  },
};
</script>

<style lang="scss"></style>
